import React from "react";
import { Link } from "react-router";
import { cn } from "@basaltbytes/ui/index.ts";
import { useLocale } from "remix-i18next/react";

import type { SerializeFrom } from "@shuken/shared/utils/serialization.ts";
import type { BlogPost } from "../../routes/_frontend+/blog/collection.server";

type SerializedBlogPost = SerializeFrom<BlogPost>;

export interface BlogPostPreviewProps
  extends React.PropsWithoutRef<React.ComponentProps<"article">> {
  post: SerializedBlogPost;
}

export const BlogPostPreview = ({
  post,
  className,
  "aria-labelledby": _ariaLabelledBy,
  "aria-describedby": _ariaDescribedBy,
  ...rest
}: BlogPostPreviewProps) => {
  const locale = useLocale();
  return (
    <article
      className={cn(
        "mb-8 flex flex-wrap overflow-hidden rounded-lg shadow",
        className,
      )}
      {...rest}
      aria-labelledby={_ariaLabelledBy ?? `title-${post.url}`}
      aria-describedby={_ariaDescribedBy ?? `descr-${post.url}`}
    >
      <div className="w-full rounded-l lg:w-1/2">
        <Link prefetch="intent" to={post.url} title={post.attributes.title}>
          <img
            className="object-cover"
            src={post.attributes.thumbnail}
            width="554"
            height="383"
            alt={post.attributes.title}
            loading="lazy"
          />
        </Link>
      </div>
      <div className="w-full rounded-r bg-card p-6 lg:w-1/2 lg:pt-10">
        <span className="text-sm text-muted-foreground">
          {new Intl.DateTimeFormat(locale, {
            dateStyle: "full",
            timeZone: "UTC",
          }).format(new Date(post.attributes.date))}
        </span>
        <Link prefetch="intent" to={post.url} title={post.attributes.title}>
          <h2
            className="my-4 font-heading text-2xl font-bold"
            id={_ariaLabelledBy ?? `title-${post.url}`}
          >
            {post.attributes.title}
          </h2>
        </Link>
        <p
          className="mb-4 leading-loose"
          id={_ariaDescribedBy ?? `descr-${post.url}`}
        >
          {post.attributes.description}
        </p>
        <Link
          prefetch="intent"
          className="font-bold text-indigo-600 hover:text-indigo-700 dark:text-indigo-300 dark:hover:text-indigo-100"
          to={post.url}
          title={post.attributes.title}
        >
          Lire l'article
        </Link>
      </div>
    </article>
  );
};
