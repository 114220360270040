import { useEffect, useState } from "react";
import type { NavLinkProps } from "react-router";
import { Link, NavLink, useNavigation } from "react-router";
import { Button } from "@basaltbytes/ui/button";
import { cn } from "@basaltbytes/ui/index";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@basaltbytes/ui/sheet";
import { ThemeToggle } from "@basaltbytes/ui/theme-toggle.tsx";
import { MenuIcon } from "lucide-react";

export interface MenuItem extends NavLinkProps {
  isHash?: boolean | undefined;
  drawerCloseOnClick?: boolean | undefined;
  title: string;
}
const menu: MenuItem[] = [
  { title: "À propos", to: "/blog/bienvenue-atelier-egon" },
  { title: "Les cours", to: "/#pricing", isHash: true },
  { title: "Formations/Stages", to: "/formations", isHash: true },
  { title: "Ateliers de groupe", to: "/ateliers-de-groupe" },
  {
    title: "Cartes Cadeau",
    to: "/cartes-cadeau",
    drawerCloseOnClick: true,
    prefetch: "intent",
  },
  { title: "Créations des élèves", to: "/#showcase", isHash: true },
  { title: "F.A.Q.", to: "/faq" },
  { title: "Contact", to: "/#contactus", isHash: true },
  { title: "Blog", to: "/blog/" },
];

export const Navbar = ({
  settings,
  // user,
}: {
  settings: {
    logo?: string | null;
    lightLogo?: string | null;
    title: string;
    description?: string | null;
  };
  user?: { name: string; email?: string } | undefined | null;
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const transition = useNavigation();

  useEffect(() => {
    if (transition.state === "idle") {
      setSidebarOpen(false);
    }
  }, [transition.state]);

  return (
    <nav className="flex items-center gap-2 bg-indigo-950 p-6">
      <Link to="/">
        <img
          src={
            "https://res.cloudinary.com/phildl-cloudinary/image/upload/h_40,w_208/f_auto,q_auto/v1714651123/atelieregon/Atelier-Egon-white_mhm40n.png"
          }
          alt={settings.title}
          className="w-52"
          // loading="lazy"
          width={"208px"}
          height={"40px"}
        />
      </Link>

      <div className="flex flex-1 items-center">
        <ul className="hidden lg:mx-auto lg:flex lg:w-auto lg:items-center lg:space-x-6">
          {menu.map((item, index) => (
            <DesktopNavlink key={index} {...item}>
              {item.title}
            </DesktopNavlink>
          ))}
        </ul>
      </div>

      <ThemeToggle className="hidden text-white hover:bg-indigo-800 hover:text-white md:flex" />
      <Link
        to="/events"
        className="hidden rounded-l-xl rounded-t-xl bg-gradient-to-r from-blue-400 via-blue-500 to-green-600 px-6 py-2 text-base font-bold text-white transition duration-200 hover:bg-gradient-to-r hover:from-green-500 hover:via-blue-500 hover:to-blue-400 lg:block"
        prefetch="intent"
      >
        Réservations
      </Link>
      <Sheet onOpenChange={setSidebarOpen} open={sidebarOpen}>
        <SheetTrigger asChild>
          <Button
            variant={"outline"}
            size={"sm"}
            className="ml-2 text-white lg:hidden"
          >
            <span className="sr-only">Menu</span>
            <MenuIcon className="h-6 w-6" />
          </Button>
        </SheetTrigger>
        <SheetContent
          side="left"
          className="light h-full min-h-full min-w-[320px] md:w-[450px]"
        >
          <SheetHeader>
            <SheetTitle className="text-left font-heading text-4xl">
              Atelier Egon
            </SheetTitle>
          </SheetHeader>
          <div className="mb-16 max-h-[90%] w-full overflow-y-auto">
            <nav className="mt-8 h-full w-full space-y-1 px-4 pr-4">
              <ul className="flex w-full flex-col gap-2">
                {menu.map((item, index) => (
                  <MobileNavlink
                    key={index}
                    {...item}
                    onClick={() =>
                      item.isHash || item.drawerCloseOnClick
                        ? setSidebarOpen(false)
                        : null
                    }
                  >
                    {item.title}
                  </MobileNavlink>
                ))}
                <li className="w-full">
                  <Link
                    to="/reservations"
                    className="-ml-4 block w-full rounded-md bg-gradient-to-r from-blue-400 via-blue-500 to-green-600 px-4 py-4 text-sm font-bold text-white transition duration-200 hover:bg-gradient-to-r hover:from-green-500 hover:via-blue-500 hover:to-blue-400"
                  >
                    Réservations
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          {/* <SheetFooter>Footer</SheetFooter> */}
        </SheetContent>
      </Sheet>
    </nav>
  );
};

interface MobileNavlinkProps extends NavLinkProps {
  isHash?: boolean;
  drawerCloseOnClick?: boolean;
}
export const MobileNavlink = ({
  children,
  className,
  isHash,
  drawerCloseOnClick: _,
  ...rest
}: MobileNavlinkProps) => {
  return (
    <li className="w-full">
      <NavLink
        prefetch="render"
        className={({ isActive }) =>
          cn(
            "block w-full whitespace-nowrap py-2 text-base font-bold sm:py-4",
            isActive && !isHash && "underline",
            className,
          )
        }
        {...rest}
      >
        {children}
      </NavLink>
    </li>
  );
};

interface DesktopNavlinkProps extends NavLinkProps {
  isHash?: boolean;
  drawerCloseOnClick?: boolean;
}
export const DesktopNavlink = ({
  children,
  className,
  isHash,
  drawerCloseOnClick: _,
  ...rest
}: DesktopNavlinkProps) => {
  return (
    <li>
      <NavLink
        className={({ isActive }) =>
          cn(
            "whitespace-nowrap text-sm font-bold text-white",
            isActive && !isHash ? "underline" : "",
            className,
          )
        }
        prefetch="intent"
        {...rest}
      >
        {children}
      </NavLink>
    </li>
  );
};
