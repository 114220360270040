import { Link } from "react-router";
import {
  Facebook,
  Github,
  Instagram,
  Linkedin,
  Rss,
  Twitter,
  Youtube,
} from "lucide-react";

type SocialLinksProps = {
  className?: string;
  facebook?: string | null;
  twitter?: string | null;
  linkedin?: string | null;
  github?: string | null;
  instagram?: string | null;
  youtube?: string | null;
  rss?: string | null;
};

export const SocialLinks = ({
  className,
  facebook,
  twitter,
  linkedin,
  github,
  instagram,
  youtube,
  rss,
}: SocialLinksProps) => {
  return (
    <div className={className}>
      {facebook && (
        <Link
          to={facebook}
          className="flex h-8 w-8 items-center justify-center rounded bg-blue-800 hover:bg-blue-600"
        >
          <Facebook className="h-4 w-4 text-white" />
          <span className="sr-only">Facebook Profile</span>
        </Link>
      )}
      {twitter && (
        <Link
          to={twitter}
          className="flex h-8 w-8 items-center justify-center rounded bg-cyan-500 hover:bg-cyan-300"
        >
          <Twitter className="h-4 w-4 text-white" />
          <span className="sr-only">Twitter Profile</span>
        </Link>
      )}
      {linkedin && (
        <Link
          to={linkedin}
          className="flex h-8 w-8 items-center justify-center rounded bg-blue-500 hover:bg-blue-300"
        >
          <Linkedin className="h-4 w-4 text-white" />
          <span className="sr-only">LinkedIn Profile</span>
        </Link>
      )}
      {github && (
        <Link
          to={github}
          className="flex h-8 w-8 items-center justify-center rounded bg-gray-800 hover:bg-gray-600"
        >
          <Github className="h-4 w-4 text-white" />
          <span className="sr-only">GitHub Organization</span>
        </Link>
      )}
      {instagram && (
        <Link
          to={instagram}
          className="flex h-8 w-8 items-center justify-center rounded bg-pink-500 hover:bg-pink-300"
        >
          <Instagram className="h-4 w-4 text-white" />
          <span className="sr-only">Instagram Profile</span>
        </Link>
      )}
      {youtube && (
        <Link
          to={youtube}
          className="flex h-8 w-8 items-center justify-center rounded bg-red-800 hover:bg-red-600"
        >
          <Youtube className="h-4 w-4 text-white" />
          <span className="sr-only">YouTube Channel</span>
        </Link>
      )}
      {rss && (
        <Link
          to={rss}
          className="flex h-8 w-8 items-center justify-center rounded bg-red-500 hover:bg-red-300"
        >
          <Rss className="h-4 w-4 text-white" />
          <span className="sr-only">RSS Feed</span>
        </Link>
      )}
    </div>
  );
};
