import * as React from "react";
import { Link, useNavigation, type LinkProps } from "react-router";
import type { VariantProps } from "class-variance-authority";
import { LoaderIcon } from "lucide-react";

import { buttonVariants } from "./button.tsx";
import { cn } from "./utils/cn.ts";

interface LinkButtonProps
  extends LinkProps,
    VariantProps<typeof buttonVariants> {}

const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <Link
        className={cn(
          "no-underline",
          buttonVariants({ variant, size, className }),
        )}
        ref={ref}
        {...props}
      >
        {props.children}
      </Link>
    );
  },
);
LinkButton.displayName = "LinkButton";

interface LinkIconButtonProps
  extends LinkProps,
    VariantProps<typeof buttonVariants> {
  loaderPathMatch?: string;
}

const LinkIconButton = React.forwardRef<HTMLAnchorElement, LinkIconButtonProps>(
  ({ className, variant, size, to, loaderPathMatch, ...props }, ref) => {
    const navigation = useNavigation();
    return (
      <Link
        className={cn(
          "no-underline",
          buttonVariants({ variant, size, className }),
        )}
        ref={ref}
        to={to}
        {...props}
      >
        {navigation.state !== "idle" &&
        navigation.location.pathname.includes(
          loaderPathMatch
            ? loaderPathMatch
            : typeof to === "object"
              ? (to.pathname ?? "/")
              : to,
        ) ? (
          <LoaderIcon className="h-4 w-4 animate-spin" />
        ) : (
          props.children
        )}
      </Link>
    );
  },
);
LinkIconButton.displayName = "LinkIconButton";

export { LinkButton, LinkIconButton };
