import React from "react";
import { Link } from "react-router";
import { cn } from "@basaltbytes/ui";
import { useTranslation } from "react-i18next";

interface HeroProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
  showForm?: boolean;
  backdropBlur?: boolean;
  image?: string;
}
const Hero = React.forwardRef<HTMLDivElement, HeroProps>(
  (
    {
      className,
      showForm: _s,
      backdropBlur: _b,
      image: _i,
      description: _d,
      title: _t,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    // let bgClassName = "bg-gradient-to-br from-cornflower-500 to-pink-500";
    return (
      <div
        className={cn(
          "radius-for-skewed bg-indigo-950 pb-20 pt-12 lg:pt-20",
          className,
        )}
        ref={ref}
        {...props}
      >
        <div className="px-4 lg:container lg:mx-auto lg:max-w-6xl">
          <div className="-mx-4 flex flex-wrap">
            <div className="mb-12 flex w-full items-center px-4 md:mb-20 lg:mb-0 lg:w-1/2">
              <div className="w-full text-center lg:text-left">
                <div className="mx-auto max-w-md lg:mx-0">
                  <h1 className="mb-3 bg-gradient-to-r from-blue-100 via-blue-500 to-green-500 bg-clip-text font-heading text-4xl font-bold text-transparent lg:text-6xl">
                    <span>{t("potteryClass")}</span> de{" "}
                    <span className="text-white">10h</span> à{" "}
                    <span className="text-white">19h</span> à la Réunion
                  </h1>
                </div>
                <div className="mx-auto max-w-sm lg:mx-0">
                  <p className="mb-6 leading-loose text-white">
                    En semaine, de 10h à 19h à St Denis de la Réunion, fermé le
                    Mercredi. <br />
                    <b className="bg-indigo-600 px-2 py-1">
                      Cours individuels ou en duo/trio,
                    </b>{" "}
                    initiation à la Poterie, Modelage et Tournage. Des Ateliers
                    de groupes sont également programmés.
                  </p>
                  <div>
                    <Link
                      className="mb-3 inline-block w-full rounded-l-xl rounded-t-xl bg-gradient-to-r from-blue-400 via-blue-500 to-green-600 px-6 py-2 font-semibold leading-loose text-white hover:bg-gradient-to-r hover:from-green-500 hover:via-blue-500 hover:to-blue-400 lg:mb-0 lg:mr-3 lg:w-auto"
                      to="/reservations/"
                    >
                      Réservation
                    </Link>
                    <Link
                      className="inline-block w-full rounded-l-xl rounded-t-xl border-2 border-blue-400 bg-indigo-950 px-6 py-2 font-semibold leading-loose text-white transition duration-200 hover:border-yellow-500 lg:w-auto"
                      to="/#features"
                    >
                      Plus d'informations
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full items-center justify-center px-4 lg:w-1/2">
              <div className="group relative z-0">
                <img
                  className="h-128 w-full max-w-lg rounded-3xl object-cover md:rounded-br-none"
                  src="https://res.cloudinary.com/phildl-cloudinary/image/upload/h_512/f_auto,q_auto:eco/atelieregon/hero-unit-md"
                  alt="Example of pottery made in Atelier Egon"
                  width="512"
                  height="512"
                  fetchPriority="high"
                />

                <img
                  className="absolute hidden transition-transform duration-1000 group-hover:-translate-y-4 md:block"
                  style={{ top: "-2rem", right: "3rem", zIndex: "-1" }}
                  src="/assets/elements/blue-up.svg"
                  alt="Abstract shape, blue triangle pointing up"
                  loading="lazy"
                />
                <img
                  className="absolute hidden transition-transform duration-1000 group-hover:translate-x-6 group-hover:translate-y-6 md:block"
                  style={{ bottom: "-2rem", right: "-2rem", zIndex: "-1" }}
                  src="/assets/elements/wing-pink-down.svg"
                  alt="Abstract shape, pink wing like object pointing down"
                  loading="lazy"
                />
                <img
                  className="absolute hidden transition-transform duration-1000 group-hover:translate-x-6 md:block"
                  style={{ top: "3rem", right: "-3rem", zIndex: "-1" }}
                  src="/assets/elements/bullets-yellow-right.svg"
                  alt="Abstract shapes, yellow bullets"
                  loading="lazy"
                />
                <img
                  className="absolute hidden transition-transform duration-1000 group-hover:-translate-x-2 md:block"
                  style={{ bottom: "2.5rem", left: "-4.5rem", zIndex: "-1" }}
                  src="/assets/elements/bullets-yellow-left.svg"
                  alt="Abstract shapes, yellow bullets"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mr-for-radius">
          <svg
            className="h-8 w-full text-indigo-950 md:h-12 lg:h-20"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
      </div>
    );
  },
);
Hero.displayName = "Hero";

export { Hero };
