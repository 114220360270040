/* eslint-disable @typescript-eslint/no-wrapper-object-types */
import type { useLoaderData } from "react-router";
import type {
  EmptyObject,
  IsAny,
  IsNever,
  IsUnknown,
  JsonValue,
  NegativeInfinity,
  PositiveInfinity,
  TypedArray,
  UnknownArray,
} from "type-fest";
import { UndefinedToOptional } from "type-fest/source/internal/object";

import type { NoUndefinedKeys } from "./misc.ts";

export type SerializeFrom<T> = ReturnType<typeof useLoaderData<T>>;

/**
 * Identity function that serializes an object to JSON.
 *
 * @param input JSON output
 * @returns input
 */
export const serializeFrom = <T>(
  input: SerializableOr<T, never>,
): SerializableOr<T, unknown> => {
  return input as SerializableOr<T, unknown>;
};

export type SerializableOr<T, Fallback> =
  T extends NoUndefinedKeys<T>
    ? SerializeFrom<T> extends T
      ? T
      : Fallback
    : NoUndefinedKeys<T>;

export type SingleFetchJsonPrimitive = string | number | boolean | null | Date;

type NotJsonable = ((...arguments_: any[]) => any) | undefined | symbol;

type NeverToNull<T> = IsNever<T> extends true ? null : T;
type UndefinedToNull<T> = T extends undefined ? null : T;

// Handles tuples and arrays
type JsonifyList<T extends UnknownArray> = T extends readonly []
  ? []
  : T extends readonly [infer F, ...infer R]
    ? [NeverToNull<Jsonify<F>>, ...JsonifyList<R>]
    : IsUnknown<T[number]> extends true
      ? []
      : Array<
          T[number] extends NotJsonable
            ? null
            : Jsonify<UndefinedToNull<T[number]>>
        >;

type FilterJsonableKeys<T extends object> = {
  [Key in keyof T]: T[Key] extends NotJsonable ? never : Key;
}[keyof T];

/**
JSON serialize objects (not including arrays) and classes.
*/
type JsonifyObject<T extends object> = {
  [Key in keyof Pick<T, FilterJsonableKeys<T>>]: Jsonify<T[Key]>;
};

export type Jsonify<T> =
  IsAny<T> extends true
    ? any
    : T extends PositiveInfinity | NegativeInfinity
      ? null
      : T extends SingleFetchJsonPrimitive
        ? T
        : // Any object with toJSON is special case
          T extends { toJSON(): infer J }
          ? (() => J) extends () => JsonValue // Is J assignable to JsonValue?
            ? J // Then T is Jsonable and its Jsonable value is J
            : Jsonify<J> // Maybe if we look a level deeper we'll find a JsonValue
          : // Instanced primitives are objects
            T extends Number
            ? number
            : T extends String
              ? string
              : T extends Boolean
                ? boolean
                : T extends Date
                  ? Date
                  : T extends Map<any, any> | Set<any>
                    ? EmptyObject
                    : T extends TypedArray
                      ? Record<string, number>
                      : T extends NotJsonable
                        ? never // Non-JSONable type union was found not empty
                        : T extends UnknownArray
                          ? JsonifyList<T>
                          : T extends object
                            ? JsonifyObject<UndefinedToOptional<T>> // JsonifyObject recursive call for its children
                            : never; // Otherwise any other non-object is removed

export function serializeEntities<T>(obj: T): Jsonify<T> {
  // Primitive type handling
  if (obj === null || typeof obj !== "object") {
    return obj as Jsonify<T>;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => serializeEntities(item)) as Jsonify<T>;
  }
  if (obj instanceof Date) {
    return obj as Jsonify<T>;
  }
  // Object with custom toJSON method
  if (obj && typeof (obj as any).toJSON === "function") {
    return (obj as any).toJSON();
  }
  // Regular object serialization
  if (obj instanceof Object) {
    const result: Record<string, any> = {};
    for (const [key, value] of Object.entries(obj)) {
      // Skip functions and symbols
      if (typeof value !== "function" && typeof value !== "symbol") {
        result[key] = serializeEntities(value);
      }
    }
    return result as Jsonify<T>;
  }
  return obj as Jsonify<T>;
}
