import React from "react";
import { cn } from "@basaltbytes/ui";

import { SkewedBottom, SkewedTop } from "./skewed-shapes.tsx";

interface SectionProps extends React.ComponentPropsWithoutRef<"section"> {
  skewed?: ("skewed-top-left" | "skewed-bottom-right")[];
}

export const Section = ({
  className,
  id,
  skewed = [],
  ...rest
}: SectionProps) => {
  return (
    <section className={cn(...skewed)} id={id} {...rest}>
      {skewed.includes("skewed-top-left") && <SkewedTop />}
      <div
        className={cn(
          "py-20",
          className,
          skewed && skewed.length ? "radius-for-skewed" : "",
        )}
      >
        <div className="px-4 lg:container lg:mx-auto lg:max-w-6xl">
          {rest.children}
        </div>
      </div>
      {skewed.includes("skewed-bottom-right") && <SkewedBottom />}
    </section>
  );
};
