import type { FormProps } from "react-router";
import { Form } from "react-router";
import { Input } from "@basaltbytes/ui/input.tsx";
import { Label } from "@basaltbytes/ui/label.tsx";
import { Textarea } from "@basaltbytes/ui/textarea.tsx";
import { useTranslation } from "react-i18next";
import { HoneypotInputs } from "remix-utils/honeypot/react";

import { SocialLinks } from "./social-links.tsx";

type FooterContactProps = {
  phone: string;
  address: {
    name: string;
    street: string;
    street2: string;
    zip: string;
    city: string;
    country: string;
  };
  formAction: string;
  socials?: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    youtube?: string;
    linkedin?: string;
  };
};
export const FooterContact = ({
  phone,
  address,
  formAction,
  socials,
}: FooterContactProps) => {
  return (
    <section
      id="contactus"
      className="px-4 py-20 lg:container lg:mx-auto lg:max-w-6xl"
    >
      <div className="mb-12">
        <h2 className="font-heading text-4xl font-bold lg:text-5xl">
          Nous contacter
        </h2>
        <p className="leading-loose text-muted-foreground">
          Vous pouvez directement{" "}
          <a href="/reservations/" className="font-bold text-pink-500">
            réserver en ligne
          </a>{" "}
          ou me contacter par Texto au{" "}
          <a href={"tel:" + phone} className="font-bold">
            {phone}
          </a>{" "}
          mais si vous avez d'autres questions c'est ici.
        </p>
      </div>
      <div className="flex flex-wrap">
        <div className="mb-16 w-full lg:mb-0 lg:w-1/2">
          <div className="flex flex-wrap">
            <div className="mb-12 w-full md:w-1/2 lg:w-1/2">
              <h3 className="mb-2 text-3xl font-bold lg:text-3xl">
                {address.name}
              </h3>
              <p className="text-primary">{address.street}</p>
              <p className="text-primary">{address.street2}</p>
              <p className="text-primary">
                {address.zip}
                {address.city ? `, ${address.city}` : ""}
              </p>
              <p className="text-primary">{address.country}</p>
            </div>
            <div className="mb-12 w-full md:w-1/2">
              <h3 className="mb-2 text-3xl font-bold lg:text-3xl">Tel</h3>
              <p>
                <a href={"tel:" + phone} className="font-bold text-primary">
                  {phone}
                </a>
              </p>
            </div>
            <div className="w-full md:w-1/3 lg:w-full">
              <h3 className="mb-2 text-3xl font-bold lg:text-3xl">
                Réseaux sociaux
              </h3>
              <div className="flex">
                <SocialLinks
                  className="mr-3 flex flex-row gap-2"
                  facebook={socials?.facebook}
                  twitter={socials?.twitter}
                  instagram={socials?.instagram}
                  youtube={socials?.youtube}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="max-w-md lg:mx-auto">
            <ContactForm action={formAction} />
          </div>
        </div>
      </div>
    </section>
  );
};

export interface ContactFormProps
  extends FormProps,
    React.RefAttributes<HTMLFormElement> {
  errors?: {
    name?: string | string[] | null;
    email?: string | string[] | null;
    message?: string | string[] | null;
    subject?: string | string[] | null;
  };
  email?: string;
  name?: string;
  message?: string;
  subject?: string;
}

export const ContactForm = ({
  errors,
  email,
  name,
  message,
  subject,
  ...formProps
}: ContactFormProps) => {
  const { t } = useTranslation();
  return (
    <Form {...formProps} method="post" aria-label="Formulaire de contact">
      <HoneypotInputs />
      <div className="mb-4">
        <Label htmlFor="subject" className="sr-only">
          Votre Question
        </Label>
        <Input
          type="text"
          name="subject"
          id="subject"
          placeholder="Votre Question"
          defaultValue={subject ?? ""}
        />
        {errors && errors.subject && (
          <p className="text-sm text-red-600">{t(errors.subject)}</p>
        )}
      </div>
      <div className="mb-4">
        <Label htmlFor="name" className="sr-only">
          Nom, Prénom
        </Label>
        <Input
          type="text"
          name="name"
          id="name"
          placeholder="Nom, Prénom"
          defaultValue={name ?? ""}
        />
        {errors && errors.name && (
          <p className="text-sm text-red-600">{t(errors.name)}</p>
        )}
      </div>
      <div className="mb-4">
        <Label htmlFor="email" className="sr-only">
          Email
        </Label>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          required={true}
          defaultValue={email ?? ""}
        />
        {errors && errors.email && (
          <p className="text-sm text-red-600">{t(errors.email)}</p>
        )}
      </div>
      <div className="mb-4">
        <Label htmlFor="message" className="sr-only">
          Votre message
        </Label>
        <Textarea
          name="message"
          id="message"
          placeholder="Votre message..."
          defaultValue={message ?? ""}
        />
        {errors && errors.message && (
          <p className="text-sm text-red-600">{t(errors.message)}</p>
        )}
      </div>
      <div className="mb-8 flex items-center justify-between">
        <button
          type="submit"
          className="inline-block rounded-l-xl rounded-t-xl bg-pink-600 px-6 py-2 font-bold leading-loose text-white transition duration-200 hover:bg-pink-700"
        >
          Envoyer ma demande
        </button>
      </div>
    </Form>
  );
};
