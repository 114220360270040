/**
 * This file contains utilities for using client hints for user preference which
 * are needed by the server, but are only known by the browser.
 */
import * as React from "react";
import { useRevalidator, useRouteLoaderData } from "react-router";
import { getHintUtils } from "@basaltbytes/web-utils/client-hints";
import {
  clientHint as colorSchemeHint,
  subscribeToSchemeChange,
} from "@basaltbytes/web-utils/client-hints/color-scheme";
import { clientHint as timeZoneHint } from "@basaltbytes/web-utils/client-hints/time-zone";
import invariant from "tiny-invariant";

import { type loader as rootLoader } from "~/root.tsx";

export function useRequestInfo() {
  const maybeRequestInfo = useOptionalRequestInfo();
  invariant(maybeRequestInfo, "No requestInfo found in root loader");

  return maybeRequestInfo;
}

const hintsUtils = getHintUtils({
  theme: colorSchemeHint,
  timeZone: timeZoneHint,
  // add other hints here
});

export const { getHints } = hintsUtils;

/**
 * @returns an object with the client hints and their values
 */
export function useHints() {
  const requestInfo = useRequestInfo();
  return requestInfo.hints;
}

export function useOptionalRequestInfo() {
  const data = useRouteLoaderData<typeof rootLoader>("root");

  return data?.requestInfo;
}

export function useOptionalHints() {
  const requestInfo = useOptionalRequestInfo();
  return requestInfo?.hints;
}

/**
 * @returns inline script element that checks for client hints and sets cookies
 * if they are not set then reloads the page if any cookie was set to an
 * inaccurate value.
 */
export function ClientHintCheck({ nonce }: { nonce: string }) {
  const { revalidate } = useRevalidator();
  React.useEffect(
    () => subscribeToSchemeChange(() => revalidate()),
    [revalidate],
  );

  return (
    <script
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: hintsUtils.getClientHintCheckScript(),
      }}
    />
  );
}
