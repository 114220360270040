import { Link } from "react-router";

import type { SerializeFrom } from "@shuken/shared/utils/serialization.ts";
import type { BlogPost } from "~/routes/_frontend+/blog/collection.server.ts";
import { BlogPostPreview } from "./blog-post-preview.tsx";
import { Section } from "./section.tsx";

type LatestBlogPostsProps = {
  posts: SerializeFrom<Omit<BlogPost, "body">[]>;
};
export const LatestBlogPosts = ({ posts }: LatestBlogPostsProps) => {
  return (
    <Section id="blog">
      <div className="mb-16 flex w-full flex-wrap items-center">
        <div className="mb-8 w-full">
          <span className="font-bold text-indigo-600 dark:text-purple-200">
            Les derniers articles de blog
          </span>
          <h2 className="mb-2 font-heading text-4xl font-bold lg:text-5xl">
            Actualité de l'Atelier
          </h2>
        </div>
        <div role="feed" aria-busy="false">
          {posts.map((post, index) => (
            <BlogPostPreview
              key={post.url}
              post={post}
              aria-setsize={posts.length}
              aria-posinset={index + 1}
              aria-labelledby={`title-blogpost-${index + 1}`}
              aria-describedby={`descr-blogpost-${index + 1}`}
              role="article"
            />
          ))}
        </div>

        <div className="flex w-full justify-end">
          <Link
            prefetch="intent"
            className="mt-4 inline-block rounded-l-xl rounded-t-xl bg-pink-600 px-6 py-2 font-bold leading-loose text-white transition duration-200 hover:bg-pink-700 lg:mt-0"
            to="/blog/"
          >
            {" "}
            Voir tous les articles
          </Link>
        </div>
      </div>
    </Section>
  );
};
